<template>
  <div class="login__container">
    <div class="login__container--content">
      <div class="left__background">
        <div class="left__background--image">
          <img
            :src="require('@/assets/images/rfp.svg')"
            alt="Request for payment logo"
          >
          <h4>Request For Payment Automation</h4>
        </div>
      </div>
      <div class="right__content">
        <div class="right__content--logo ">
          <img
            :src="getImageUrl(`branding/${getCompanyLogo()}`)"
            alt="aljay logo"
          >
        </div>
        <h4>Request For Payment Automation</h4>
        <ValidationObserver
          ref="form"
          v-slot="{ handleSubmit }"
        >
          <form
            class="mt-2  right__content--form"
            novalidate
            @submit.prevent="handleSubmit(onFormPostSubmit)"
          >
            <ValidationProvider
              #default="{ errors }"
              vid="email"
              name="email"
              rules="required|email"
            >
              <b-form-group>
                <label for="emailInput">Email</label>
                <input
                  id="emailInput"
                  v-model="credentials.email"
                  type="email"
                  :class="
                    'form-control  mb-0' +
                      (errors.length > 0 ? ' is-invalid' : '')
                  "
                  aria-describedby="emailHelp"
                  placeholder="Enter Email"
                  :disabled="isBusy"
                  autocomplete="off"
                >
                <div class="invalid-feedback">
                  <span>{{ errors[0] }}</span>
                </div>
              </b-form-group>
            </ValidationProvider>
            <ValidationProvider
              #default="{ errors }"
              vid="password"
              name="password"
              rules="required"
            >
              <b-form-group>
                <label for="passwordInput">Password</label>
                <!-- <router-link
              to="/auth/password-reset1"
              class="float-right"
            >
              Forgot password?
            </router-link> -->
                <input
                  id="passwordInput"
                  v-model="credentials.password"
                  type="password"
                  :class="
                    'form-control mb-0' +
                      (errors.length > 0 ? ' is-invalid' : '')
                  "
                  placeholder="Enter Password"
                  required
                  :disabled="isBusy"
                  autocomplete="off"
                >
                <div class="invalid-feedback">
                  <span>{{ errors[0] }}</span>
                </div>
              </b-form-group>
            </ValidationProvider>
            <div class="d-inline-block w-100">
              <b-button
                type="submit"
                variant="success"
                block
                :disabled="isBusy"
                class="mb-1"
              >
                Sign in
              </b-button>

              <b-button
                type="button"
                variant="secondary"
                block
                :disabled="isBusy"
                @click="useAuthProvider('oauth2', Oauth2)"
              >
                Sign in with Aljay SSO
              </b-button>
            </div>
          </form>
        </ValidationObserver>
      </div>
    </div>
  </div>
</template>

<script>
import { core } from '@/config/pluginInit'
import { AxiosError, SharedAuthService, SharedSSOService } from '@/services'
import { Oauth2 } from 'universal-social-auth'
import url from '@/mixins/url'
import misc from '@/mixins/misc'

export default {
  name: 'SharedSignIn',

  middleware: ['guest'],

  metaInfo () {
    return {
      title: 'Sign In'
    }
  },

  mixins: [url, misc],

  data: () => ({
    isBusy: false,
    credentials: {
      email: '',
      password: ''
    },
    Oauth2
  }),

  mounted () {
    core.index()
  },

  methods: {
    useAuthProvider (provider, proData) {
      this.$Oauth
        .authenticate(provider, proData)
        .then(data => {
          this.useSocialLogin(data)
        })
        .catch(err => {
          console.log(err)
        })
    },

    async useSocialLogin (code) {
      await SharedSSOService.post(code)
        .then(response => {
          this.$store.dispatch('auth/saveToken', response.data.token)

          SharedAuthService.FetchUser()
            .then(({ data }) => {
              this.$store.dispatch('auth/fetchUserSuccess', data)

              let routeName = 'dashboard'

              const user = this.$store.getters['auth/user']

              if (user) {
                routeName = `${user.role}.dashboard`
              }

              this.$router.replace({ name: routeName })
            })
            .catch(() => {
              this.$store.dispatch('auth/fetchUserFailure')
            })
        })
        .catch(err => {
          console.log({ err: err })
        })
    },

    async onFormPostSubmit () {
      await this.$refs.form.validate().then(async success => {
        if (success) {
          this.isBusy = true
          SharedAuthService.SignIn(this.credentials)
            .then(response => {
              this.$store.dispatch('auth/saveToken', response.data.token)

              SharedAuthService.FetchUser()
                .then(({ data }) => {
                  this.$store.dispatch('auth/fetchUserSuccess', data)

                  let routeName = 'dashboard'

                  const user = this.$store.getters['auth/user']

                  if (user) {
                    routeName = `${user.role}.dashboard`
                  }

                  this.$router.replace({ name: routeName })
                })
                .catch(() => {
                  this.$store.dispatch('auth/fetchUserFailure')
                })

              this.isBusy = false
            })
            .catch(error => {
              if (error instanceof AxiosError) {
                if (error.code === 422) {
                  this.$refs.form.setErrors(error.message)
                }
              }
              this.isBusy = false
            })
        }
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.login__container {
  width: 100%;
  height: 100vh;
  overflow: visible;
  display: grid;
  align-items: center;
  background-color: #00cba9;
  margin: 0;

  &--content {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    max-width: 70%;
    height: 500px;
    margin: auto;

    .left__background {
      background-color: rgba(0, 0, 0, 0.3);
      width: 100%;
      padding: 0 2rem;

      border-radius: 10px 0 0 10px;
      color: #f6f1f1;
      display: flex;
      flex-direction: column;
      justify-content: center;
      height: 500px;

      &--image {
        img {
          width: 100%;
          height: auto;
        }
      }
      h4 {
        padding: 0;
        margin: 0;
        margin: 2rem 0;
        color: #eee9e9;
        font-weight: 500;
        font-size: 1.5rem;
        text-align: center;
      }
    }
    .right__content {
      border-radius: 0 10px 10px 0;
      background-color: #fff;
      padding: 0 2rem;
      display: flex;
      flex-direction: column;
      justify-content: space-evenly;
      &--logo {
        margin: 0 auto;
        height: 120px;
        img {
          width: 100%;
          height: 100%;
          object-fit: contain;
        }
        h1 {
          font-size: 16px;
          color: white;
          font-weight: 600;
        }
      }
      h4 {
        padding: 0;
        margin: 0;
        margin: 2rem 0;
        color: #00cba9;
        font-weight: 500;
        font-size: 1.5rem;
        text-align: center;
      }
      &--header {
        width: 100%;
        h3 {
          font-weight: 600;
          color: rgb(76, 73, 73);
        }
        span {
          font-weight: 400;
          color: rgb(93, 90, 90);
        }
      }
    }
  }
}

@media screen and (max-width: 567px) {
  .login__container {
    width: 100%;

    &--content {
      max-width: 90%;
      height: auto;
      .left__background {
        display: none;
      }
      .right__content {
        grid-column: 1 / span 2;
        border-radius: 10px;
        padding: 2rem;
        &--logo {
          img {
            width: 100%;
          }
        }
        h4 {
          margin: 1rem 0;
          color: #00cba9;
          font-weight: 400;
          font-size: 1rem;
        }
      }
    }
  }
}
@media screen and (min-width: 568px) and (max-width: 767px) {
  .login__container {
    width: 100%;

    &--content {
      height: auto;
      width: 100%;
      .left__background {
        display: none;
      }
      .right__content {
        grid-column: 1 / span 2;
        border-radius: 10px;
         padding: 2rem;
        &--logo {
          img {
            width: 100%;
          }
        }
        h4 {
          margin: 1rem 0;
          color: #00cba9;
          font-weight: 400;
          font-size: 1rem;
        }
      }
    }
  }
}

@media screen and (min-width: 767px) and (max-width: 991px) {
  .login__container {
    width: 100%;

    &--content {
      height: auto;
      .left__background {
        h4 {
          font-size: 1.3rem;
        }
      }
      .right__content {
        &--logo {
          img {
            width: 100%;
          }
        }
        h4 {
          display: none;
        }
      }
    }
  }
}

@media screen and (min-width: 991px) and (max-width: 1199px) {
  .login__container {
    width: 100%;

    &--content {
      height: auto;
      .left__background {
        h4 {
          font-size: 1.3rem;
        }
      }
      .right__content {
        &--logo {
          img {
            width: 100%;
          }
        }
        h4 {
          display: none;
        }
      }
    }
  }
}
@media screen and (min-width: 1200px) {
  .login__container {
    &--content {
      height: auto;
      width: 70%;
      .left__background {
        h4 {
          font-size: 1.3rem;
        }
      }
      .right__content {
        &--logo {
          img {
            width: 100%;
          }
        }
        h4 {
          display: none;
        }
      }
    }
  }
}
</style>
